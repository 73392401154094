/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Quantico';
}
/* Colors */
.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.actions.bandageSection {
  min-height: 19vmax;
}
.actions .powerButton {
  margin: 2vmax !important;
}
.actions .MuiButton-contained.Mui-disabled {
  background-color: #d36200;
  -webkit-filter: brightness(0.4);
  filter: brightness(0.4);
}
.actions .power {
  width: 15vmax;
  height: 15vmax;
}
.actions .rot90 {
  transform: rotate(90deg);
}
.actions .rot180 {
  transform: rotate(180deg);
}
.actions .rot270 {
  transform: rotate(-90deg);
}
.buttonBandage {
  margin: 10px !important;
}
.buttonBandage .bandageSelection {
  width: 20vw;
  height: 20vw;
}
