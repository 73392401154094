@-webkit-keyframes myRotate {
  from {
    transform-origin: center;
    transform: rotate(0deg);
  }
  to {
    transform-origin: center;
    transform: rotate(360deg);
  }
}
@keyframes myRotate {
  from {
    transform-origin: center;
    transform: rotate(0deg);
  }
  to {
    transform-origin: center;
    transform: rotate(360deg);
  }
}
@-webkit-keyframes pop {
  from {
    transform-origin: center;
    transform: scale(1);
  }
  50% {
    transform-origin: center;
    transform: scale(1.5);
  }
  to {
    transform-origin: center;
    transform: scale(1);
  }
}
@keyframes pop {
  from {
    transform-origin: center;
    transform: scale(1);
  }
  50% {
    transform-origin: center;
    transform: scale(1.2);
  }
  to {
    transform-origin: center;
    transform: scale(1);
  }
}
.rotationHole {
  -webkit-animation: 10s linear 0s infinite myRotate;
  animation: 10s linear 0s infinite myRotate;
}
.spinRotate {
  -webkit-animation: 4s cubic-bezier(0.77, 0, 0.18, 1) 0s infinite myRotate;
  animation: 4s cubic-bezier(0.77, 0, 0.18, 1) 0s infinite myRotate;
}
.popAnimation {
  -webkit-animation: 1s linear 0s infinite pop;
  animation: 1s linear 0s infinite pop;
}
