/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.lobby,
.lobby .lobbyButton {
  font-family: 'Quantico';
}
/* Colors */
.lobby {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  max-height: 100%;
  min-height: 90%;
}
.lobby .checklistTitle {
  font-size: large;
  text-decoration: underline;
  font-size: 4vmin;
  text-align: center;
}
.lobby .checkList {
  display: flex;
  margin-bottom: 2vh;
  margin-top: 2vh;
}
.lobby .checkList * {
  /* UNSELECTABLE TEXT*/
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}
.lobby .checkList > div {
  margin: 0 2vw;
}
.lobby .checkList > div > div {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
}
.lobby .checkList > div > div .MuiFormControlLabel-root {
  margin-right: 3vw;
  margin-left: 0 !important;
}
.lobby .checkList .MuiIconButton-colorSecondary {
  color: #d36200 !important;
}
.lobby .checkList .MuiIconButton-colorPrimary {
  color: #009e9e !important;
}
.lobby .checkList .ul2cols {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
.lobby .checkList .ulLobby {
  padding: 0 2vmax;
  font-size: 2.5vmin;
  margin: 1vh;
  list-style: none;
  /* Remove default bullets */
  /* Custom bullet */
}
.lobby .checkList .ulLobby li::before {
  content: '\2022';
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
}
.lobby .checkList .ulLobby li.liSecondary::before {
  color: #d36200;
}
.lobby .checkList .ulLobby li.liPrimary::before {
  color: #009e9e;
}
.lobby .checkList .ulLobby li.playerName {
  margin: 0 0.5vw;
}
.lobby .checkList .codeMission {
  font-size: 2.5vmin;
}
.lobby .checkList .codeMission .idRoom {
  color: #d36200;
  font-size: 5vmin;
}
.lobby .checkList .radioLabel {
  font-size: 2vmin !important;
}
.lobby .checkList .renderDifficulty {
  display: flex;
  align-items: center;
}
.lobby .checkList .renderDifficulty .iconConfig {
  height: 3vmax;
  width: 3vmax;
}
.lobby .checkList .inputConfig {
  max-width: 45px;
  margin-right: 2vw;
  margin-left: 5px;
  margin-top: 5px;
}
.lobby .checkList .inputConfig input {
  color: white;
}
.lobby .checkList .multiplier {
  margin-left: 5px;
  margin-top: 3px;
  font-family: cursive;
}
.lobby .cursor {
  margin-top: 2vh;
}
.lobby .cursor > * {
  cursor: pointer !important;
}
.lobby .loadingMaps {
  height: 45vh;
  align-items: center;
  display: flex;
}
.lobby .lobbyButton {
  font-size: 3vmin;
  margin-bottom: 2vh;
}
.lobby .giveShips {
  font-size: 2vmax;
  padding: 4vw 6vw;
}
.lobby .MuiSwitch-track {
  background-color: #fafafa;
}
.lobby .powerRotate {
  height: 5vh;
  width: 5vh;
  background-color: #c9c9c9;
  border-radius: 60px;
  margin-left: 1vw;
}
.lobby .desactivate {
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
}
