/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.end,
.end .playAgain {
  font-family: 'Quantico';
}
/* Colors */
.end {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.end.lost {
  color: #ad0707;
}
.end.win {
  color: #009e9e;
}
.end .title {
  font-size: 7vw;
}
.end .text {
  font-size: 2.5vmax;
  padding: 2vh 2vw;
  font-weight: bold;
  text-align: center;
  margin-bottom: 4vh;
}
.end .playAgain {
  font-size: 2vw;
}
