/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.GoBack button,
.mapEditor .saveBox button {
  font-family: 'Quantico';
}
/* Colors */
.GoBack {
  position: absolute;
  top: 2vmin;
  left: 2vmin;
}
.GoBack button {
  font-size: 2vmin;
}
.GoBack button svg {
  width: 1.5vmax;
  height: 1.5vmax;
}
.mapEditor {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mapEditor .textCreate {
  color: white;
  margin-bottom: 2vh;
}
.mapEditor .map {
  line-height: 0;
  margin-bottom: 1vmin;
}
.mapEditor .tile {
  cursor: pointer;
  margin: -0.05vw;
}
.mapEditor .toolbox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  margin-bottom: 3vh;
}
.mapEditor .toolbox svg {
  margin: 1vmin;
}
.mapEditor .toolbox svg.active {
  -webkit-filter: none;
  filter: none;
}
.mapEditor .toolbox svg.notActive {
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
}
.mapEditor .saveBox {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  color: white;
}
.mapEditor .saveBox button {
  padding: 1vh;
  margin: 2vh;
}
.saveMapForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.saveMapForm .warningMessage {
  color: #e62416;
}
.allMapsChoices {
  display: flex;
  flex-wrap: wrap;
}
.allMapsChoices .mapWrapper {
  margin: 2vmin;
  line-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.allMapsChoices .mapWrapper .tiles {
  -webkit-filter: brightness(0.4);
  filter: brightness(0.4);
  cursor: pointer;
}
.allMapsChoices .mapWrapper .tiles.active {
  -webkit-filter: none;
  filter: none;
}
.allMapsChoices .mapWrapper .mapName {
  margin-top: 18px;
}
.allMapsChoices .mapWrapper .tile {
  margin: -1px;
}
.deleteButton {
  background-color: #dc3545 !important;
}
.deleteButton.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

@-webkit-keyframes myRotate {
  from {
    transform-origin: center;
    transform: rotate(0deg);
  }
  to {
    transform-origin: center;
    transform: rotate(360deg);
  }
}
@keyframes myRotate {
  from {
    transform-origin: center;
    transform: rotate(0deg);
  }
  to {
    transform-origin: center;
    transform: rotate(360deg);
  }
}
@-webkit-keyframes pop {
  from {
    transform-origin: center;
    transform: scale(1);
  }
  50% {
    transform-origin: center;
    transform: scale(1.5);
  }
  to {
    transform-origin: center;
    transform: scale(1);
  }
}
@keyframes pop {
  from {
    transform-origin: center;
    transform: scale(1);
  }
  50% {
    transform-origin: center;
    transform: scale(1.2);
  }
  to {
    transform-origin: center;
    transform: scale(1);
  }
}
.rotationHole {
  -webkit-animation: 10s linear 0s infinite myRotate;
  animation: 10s linear 0s infinite myRotate;
}
.spinRotate {
  -webkit-animation: 4s cubic-bezier(0.77, 0, 0.18, 1) 0s infinite myRotate;
  animation: 4s cubic-bezier(0.77, 0, 0.18, 1) 0s infinite myRotate;
}
.popAnimation {
  -webkit-animation: 1s linear 0s infinite pop;
  animation: 1s linear 0s infinite pop;
}

.langSelector {
  position: absolute !important;
  left: 1vw;
  bottom: 1vh;
}
.langSelector .MuiSelect-nativeInput {
  width: 90%;
}

/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.textDrawer {
  font-family: 'Quantico';
}
/* Colors */
.wrapper {
  height: 30vh;
}
.drawerInstall {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.iconDrawer {
  width: 15vh;
  height: 15vh;
  padding: 3vw;
}
.textDrawer {
  font-weight: bold;
  font-size: 3vh;
  padding: 2vw;
  text-align: center;
}
.buttons {
  padding-top: 2vh;
  display: flex;
  justify-content: space-evenly;
}
.btnDrawer {
  padding: 4vw;
}

/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.lobby,
.lobby .lobbyButton {
  font-family: 'Quantico';
}
/* Colors */
.lobby {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  max-height: 100%;
  min-height: 90%;
}
.lobby .checklistTitle {
  font-size: large;
  text-decoration: underline;
  font-size: 4vmin;
  text-align: center;
}
.lobby .checkList {
  display: flex;
  margin-bottom: 2vh;
  margin-top: 2vh;
}
.lobby .checkList * {
  /* UNSELECTABLE TEXT*/
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}
.lobby .checkList > div {
  margin: 0 2vw;
}
.lobby .checkList > div > div {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
}
.lobby .checkList > div > div .MuiFormControlLabel-root {
  margin-right: 3vw;
  margin-left: 0 !important;
}
.lobby .checkList .MuiIconButton-colorSecondary {
  color: #d36200 !important;
}
.lobby .checkList .MuiIconButton-colorPrimary {
  color: #009e9e !important;
}
.lobby .checkList .ul2cols {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
.lobby .checkList .ulLobby {
  padding: 0 2vmax;
  font-size: 2.5vmin;
  margin: 1vh;
  list-style: none;
  /* Remove default bullets */
  /* Custom bullet */
}
.lobby .checkList .ulLobby li::before {
  content: '\2022';
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
}
.lobby .checkList .ulLobby li.liSecondary::before {
  color: #d36200;
}
.lobby .checkList .ulLobby li.liPrimary::before {
  color: #009e9e;
}
.lobby .checkList .ulLobby li.playerName {
  margin: 0 0.5vw;
}
.lobby .checkList .codeMission {
  font-size: 2.5vmin;
}
.lobby .checkList .codeMission .idRoom {
  color: #d36200;
  font-size: 5vmin;
}
.lobby .checkList .radioLabel {
  font-size: 2vmin !important;
}
.lobby .checkList .renderDifficulty {
  display: flex;
  align-items: center;
}
.lobby .checkList .renderDifficulty .iconConfig {
  height: 3vmax;
  width: 3vmax;
}
.lobby .checkList .inputConfig {
  max-width: 45px;
  margin-right: 2vw;
  margin-left: 5px;
  margin-top: 5px;
}
.lobby .checkList .inputConfig input {
  color: white;
}
.lobby .checkList .multiplier {
  margin-left: 5px;
  margin-top: 3px;
  font-family: cursive;
}
.lobby .cursor {
  margin-top: 2vh;
}
.lobby .cursor > * {
  cursor: pointer !important;
}
.lobby .loadingMaps {
  height: 45vh;
  align-items: center;
  display: flex;
}
.lobby .lobbyButton {
  font-size: 3vmin;
  margin-bottom: 2vh;
}
.lobby .giveShips {
  font-size: 2vmax;
  padding: 4vw 6vw;
}
.lobby .MuiSwitch-track {
  background-color: #fafafa;
}
.lobby .powerRotate {
  height: 5vh;
  width: 5vh;
  background-color: #c9c9c9;
  border-radius: 60px;
  margin-left: 1vw;
}
.lobby .desactivate {
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
}

/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.ping {
  font-family: 'Quantico';
}
/* Colors */
.ping {
  color: white;
  position: absolute;
  top: 1vh;
  left: 2vw;
  font-size: 1.5vmax;
}
.pingGreen {
  color: lime;
}
.pingOrange {
  color: orange;
}
.pingRed {
  color: orangered;
}

/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.roomInit .title,
.roomInit button,
.roomInit .version {
  font-family: 'Quantico';
}
/* Colors */
.roomInit {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.roomInit .linkWrapper {
  position: absolute;
  top: 1vh;
  right: 1vw;
}
.roomInit .linkWrapper .linkMailTo {
  color: grey;
  font-size: 1.4vmax;
}
.roomInit .title {
  color: #009e9e;
  font-size: 12vmin;
  margin-bottom: 5vh;
}
.roomInit .MuiPaper-root.MuiPaper-rounded {
  max-width: min(500px, 100vw);
}
.roomInit .tab {
  padding: 5vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.roomInit .tab button {
  padding: 1vmax;
  margin-top: 1vmax;
}
.roomInit .tab .warningMessage {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 2vmin;
  border: 1px solid;
  border-radius: 6px;
  margin-top: 2vmin;
}
.roomInit .tab > div {
  margin-bottom: 2vmin;
}
.roomInit .tab .buttonWrapper {
  display: flex;
}
.roomInit .tab .buttonWrapper > button {
  margin: 1vmin;
}
.roomInit .tab .margin-left {
  margin-left: 1vmin;
  width: 3vmin;
  height: 3vmin;
}
.roomInit .buttons-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 3vmin;
}
.roomInit .buttons-wrapper > button:first-child {
  margin-right: 1vmin;
}
.roomInit .logo {
  width: 15vmax;
  max-width: 20vh;
  max-height: 20vh;
}
.roomInit .version {
  color: white;
  position: absolute;
  bottom: 1vh;
  right: 2vw;
  font-size: 2vmax;
}
.roomInit .easterEgged {
  -webkit-animation: 4s linear 0s infinite myHueRotate;
  animation: 4s linear 0s infinite myHueRotate;
}
@-webkit-keyframes myHueRotate {
  from {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
  }
}
@keyframes myHueRotate {
  from {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
  }
}
/* fix the first slide ever des react-swipeableView */
.react-swipeable-view-container {
  transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important;
}

.react-sweet-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.react-sweet-progress-symbol {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 35px;
  height: 20px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 200;
}
.react-sweet-progress-symbol-absolute {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  transform: translate3d(-50%, -50%, 0);
}
.react-sweet-progress-symbol-absolute .react-sweet-progress-symbol {
  padding: 0;
  width: 100%;
}
.react-sweet-progress-circle-outer {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.react-sweet-progress-line {
  width: 100%;
  border-radius: 100px;
  background-color: #efefef;
  vertical-align: middle;
}
.react-sweet-progress-line-inner {
  position: relative;
  min-height: 10px;
  border-radius: 100px;
  transition: width 0.3s ease;
}
.react-sweet-progress-line-inner-status-active:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background: #fff;
  -webkit-animation: active-anim 2s cubic-bezier(0.25, 1, 0.6, 1) infinite;
  animation: active-anim 2s cubic-bezier(0.25, 1, 0.6, 1) infinite;
  content: '';
  opacity: 0;
}
@-webkit-keyframes active-anim {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  to {
    width: 100%;
    opacity: 0;
  }
}
@keyframes active-anim {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  to {
    width: 100%;
    opacity: 0;
  }
}
.react-sweet-progress-circle path {
  stroke-linecap: round;
}

/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Quantico';
}
/* Colors */
.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.actions.bandageSection {
  min-height: 19vmax;
}
.actions .powerButton {
  margin: 2vmax !important;
}
.actions .MuiButton-contained.Mui-disabled {
  background-color: #d36200;
  -webkit-filter: brightness(0.4);
  filter: brightness(0.4);
}
.actions .power {
  width: 15vmax;
  height: 15vmax;
}
.actions .rot90 {
  transform: rotate(90deg);
}
.actions .rot180 {
  transform: rotate(180deg);
}
.actions .rot270 {
  transform: rotate(-90deg);
}
.buttonBandage {
  margin: 10px !important;
}
.buttonBandage .bandageSelection {
  width: 20vw;
  height: 20vw;
}

/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.titleModal {
  font-family: 'Quantico';
}
/* Colors */
.main {
  color: white;
  flex-direction: column;
}
.main .mainContent {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  line-height: 0;
  align-items: center;
}
.main .backpack {
  display: flex;
  align-items: center;
}
.main .backpack .sacados {
  width: 10vh;
  height: 10vh;
  z-index: 10;
}
.main .backpack .slot {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2vh;
  min-width: 10vh;
  background-color: #588cb3;
  margin-top: 2.5vh;
}
.main .backpack .slot.firstSlot {
  margin-left: -3vw;
  z-index: 0;
}
.main .backpack .slot.secondSlot {
  border-radius: 0px 8px 8px 0px;
}
.main .backpack .slot.secondSlot .item {
  margin-left: 1vw !important;
}
.main .backpack .slot .item {
  margin-left: 3vw;
}
.main .backpack .slot .bandage {
  height: 10vh;
  width: 10vh;
}
.main .backpack .slot .emptyDot {
  height: 3vmin;
  width: 3vmin;
}
.main .hud {
  padding: 2vh 5vw;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.main .hud .progressContainer {
  width: 12vh;
  height: 12vh;
}
.main .hud .O2Progress {
  height: 16vh;
  width: 16vh;
}
.main .tile {
  margin: -2px;
}
.main .trip {
  width: 100%;
  margin-top: 20px;
}
.main .trip .miniShip {
  height: 30px;
  width: 30px;
  position: relative;
  transform: rotate(45deg);
  top: -20px;
}
.main .trip .react-sweet-progress-symbol {
  display: none;
}
/* La modal est en dehors de main malheureusement ...*/
.titleModal {
  text-align: center;
  font-size: 3vmax;
}

/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.tuto,
.tuto .startButton {
  font-family: 'Quantico';
}
/* Colors */
.tuto {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #009e9e;
}
.tuto .step {
  display: flex;
  flex: 100 1;
  width: 70%;
  align-items: center;
}
.tuto .animation {
  display: flex;
  flex: 35 1;
  max-height: 30vh;
}
.tuto .fixHole {
  height: 15vh;
}
.tuto .shuffle {
  height: 30vh;
}
.tuto .text-left {
  text-align: left !important;
}
.tuto .text {
  flex: 65 1;
  font-size: 3vh;
  padding: 2vh 2vw;
  font-weight: bold;
  text-align: right;
  align-items: center;
  display: flex;
}
.tuto .startButton {
  font-size: 2.5vw;
  margin-top: 5vh;
}
.tuto .gifWrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.tuto .gifWrapper img {
  transform: rotate(90deg);
  min-height: 105vw;
  min-width: 100vh;
}

/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.end,
.end .playAgain {
  font-family: 'Quantico';
}
/* Colors */
.end {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.end.lost {
  color: #ad0707;
}
.end.win {
  color: #009e9e;
}
.end .title {
  font-size: 7vw;
}
.end .text {
  font-size: 2.5vmax;
  padding: 2vh 2vw;
  font-weight: bold;
  text-align: center;
  margin-bottom: 4vh;
}
.end .playAgain {
  font-size: 2vw;
}

/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.appLoading {
  font-family: 'Quantico';
}
/* Colors */
html,
body,
#root,
.swipViews,
.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.appLoading,
.fullScreen {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c0d;
  width: 100%;
  height: 100%;
}
.appLoading {
  color: white;
}
.custom-scrollbar,
.fullScreen {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #009e9e transparent;
  /* Chrome */
}
.custom-scrollbar::-webkit-scrollbar,
.fullScreen::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}
.custom-scrollbar::-webkit-scrollbar-thumb,
.fullScreen::-webkit-scrollbar-thumb {
  background-color: #009e9e;
}
/** COLORS OF METEORITES **/
.meteorite2 {
  color: #435a4f;
  color: #60ae8a;
  color: #7fcaa9;
  color: #a4dac6;
}
.meteorite1 {
  color: #64225d;
  color: #d46dcb;
  color: #eb9ee2;
  color: #f5baec;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

