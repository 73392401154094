/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.GoBack button,
.mapEditor .saveBox button {
  font-family: 'Quantico';
}
/* Colors */
.GoBack {
  position: absolute;
  top: 2vmin;
  left: 2vmin;
}
.GoBack button {
  font-size: 2vmin;
}
.GoBack button svg {
  width: 1.5vmax;
  height: 1.5vmax;
}
.mapEditor {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mapEditor .textCreate {
  color: white;
  margin-bottom: 2vh;
}
.mapEditor .map {
  line-height: 0;
  margin-bottom: 1vmin;
}
.mapEditor .tile {
  cursor: pointer;
  margin: -0.05vw;
}
.mapEditor .toolbox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  margin-bottom: 3vh;
}
.mapEditor .toolbox svg {
  margin: 1vmin;
}
.mapEditor .toolbox svg.active {
  -webkit-filter: none;
  filter: none;
}
.mapEditor .toolbox svg.notActive {
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
}
.mapEditor .saveBox {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  color: white;
}
.mapEditor .saveBox button {
  padding: 1vh;
  margin: 2vh;
}
.saveMapForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.saveMapForm .warningMessage {
  color: #e62416;
}
.allMapsChoices {
  display: flex;
  flex-wrap: wrap;
}
.allMapsChoices .mapWrapper {
  margin: 2vmin;
  line-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.allMapsChoices .mapWrapper .tiles {
  -webkit-filter: brightness(0.4);
  filter: brightness(0.4);
  cursor: pointer;
}
.allMapsChoices .mapWrapper .tiles.active {
  -webkit-filter: none;
  filter: none;
}
.allMapsChoices .mapWrapper .mapName {
  margin-top: 18px;
}
.allMapsChoices .mapWrapper .tile {
  margin: -1px;
}
.deleteButton {
  background-color: #dc3545 !important;
}
.deleteButton.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
