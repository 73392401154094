/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.titleModal {
  font-family: 'Quantico';
}
/* Colors */
.main {
  color: white;
  flex-direction: column;
}
.main .mainContent {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  line-height: 0;
  align-items: center;
}
.main .backpack {
  display: flex;
  align-items: center;
}
.main .backpack .sacados {
  width: 10vh;
  height: 10vh;
  z-index: 10;
}
.main .backpack .slot {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2vh;
  min-width: 10vh;
  background-color: #588cb3;
  margin-top: 2.5vh;
}
.main .backpack .slot.firstSlot {
  margin-left: -3vw;
  z-index: 0;
}
.main .backpack .slot.secondSlot {
  border-radius: 0px 8px 8px 0px;
}
.main .backpack .slot.secondSlot .item {
  margin-left: 1vw !important;
}
.main .backpack .slot .item {
  margin-left: 3vw;
}
.main .backpack .slot .bandage {
  height: 10vh;
  width: 10vh;
}
.main .backpack .slot .emptyDot {
  height: 3vmin;
  width: 3vmin;
}
.main .hud {
  padding: 2vh 5vw;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.main .hud .progressContainer {
  width: 12vh;
  height: 12vh;
}
.main .hud .O2Progress {
  height: 16vh;
  width: 16vh;
}
.main .tile {
  margin: -2px;
}
.main .trip {
  width: 100%;
  margin-top: 20px;
}
.main .trip .miniShip {
  height: 30px;
  width: 30px;
  position: relative;
  transform: rotate(45deg);
  top: -20px;
}
.main .trip .react-sweet-progress-symbol {
  display: none;
}
/* La modal est en dehors de main malheureusement ...*/
.titleModal {
  text-align: center;
  font-size: 3vmax;
}
