/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.tuto,
.tuto .startButton {
  font-family: 'Quantico';
}
/* Colors */
.tuto {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #009e9e;
}
.tuto .step {
  display: flex;
  flex: 100 1;
  width: 70%;
  align-items: center;
}
.tuto .animation {
  display: flex;
  flex: 35 1;
  max-height: 30vh;
}
.tuto .fixHole {
  height: 15vh;
}
.tuto .shuffle {
  height: 30vh;
}
.tuto .text-left {
  text-align: left !important;
}
.tuto .text {
  flex: 65 1;
  font-size: 3vh;
  padding: 2vh 2vw;
  font-weight: bold;
  text-align: right;
  align-items: center;
  display: flex;
}
.tuto .startButton {
  font-size: 2.5vw;
  margin-top: 5vh;
}
.tuto .gifWrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.tuto .gifWrapper img {
  transform: rotate(90deg);
  min-height: 105vw;
  min-width: 100vh;
}
