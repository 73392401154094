/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.appLoading {
  font-family: 'Quantico';
}
/* Colors */
html,
body,
#root,
.swipViews,
.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.appLoading,
.fullScreen {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0c0d;
  width: 100%;
  height: 100%;
}
.appLoading {
  color: white;
}
.custom-scrollbar,
.fullScreen {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #009e9e transparent;
  /* Chrome */
}
.custom-scrollbar::-webkit-scrollbar,
.fullScreen::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}
.custom-scrollbar::-webkit-scrollbar-thumb,
.fullScreen::-webkit-scrollbar-thumb {
  background-color: #009e9e;
}
/** COLORS OF METEORITES **/
.meteorite2 {
  color: #435a4f;
  color: #60ae8a;
  color: #7fcaa9;
  color: #a4dac6;
}
.meteorite1 {
  color: #64225d;
  color: #d46dcb;
  color: #eb9ee2;
  color: #f5baec;
}
