/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.roomInit .title,
.roomInit button,
.roomInit .version {
  font-family: 'Quantico';
}
/* Colors */
.roomInit {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.roomInit .linkWrapper {
  position: absolute;
  top: 1vh;
  right: 1vw;
}
.roomInit .linkWrapper .linkMailTo {
  color: grey;
  font-size: 1.4vmax;
}
.roomInit .title {
  color: #009e9e;
  font-size: 12vmin;
  margin-bottom: 5vh;
}
.roomInit .MuiPaper-root.MuiPaper-rounded {
  max-width: min(500px, 100vw);
}
.roomInit .tab {
  padding: 5vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.roomInit .tab button {
  padding: 1vmax;
  margin-top: 1vmax;
}
.roomInit .tab .warningMessage {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 2vmin;
  border: 1px solid;
  border-radius: 6px;
  margin-top: 2vmin;
}
.roomInit .tab > div {
  margin-bottom: 2vmin;
}
.roomInit .tab .buttonWrapper {
  display: flex;
}
.roomInit .tab .buttonWrapper > button {
  margin: 1vmin;
}
.roomInit .tab .margin-left {
  margin-left: 1vmin;
  width: 3vmin;
  height: 3vmin;
}
.roomInit .buttons-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 3vmin;
}
.roomInit .buttons-wrapper > button:first-child {
  margin-right: 1vmin;
}
.roomInit .logo {
  width: 15vmax;
  max-width: 20vh;
  max-height: 20vh;
}
.roomInit .version {
  color: white;
  position: absolute;
  bottom: 1vh;
  right: 2vw;
  font-size: 2vmax;
}
.roomInit .easterEgged {
  -webkit-animation: 4s linear 0s infinite myHueRotate;
  animation: 4s linear 0s infinite myHueRotate;
}
@-webkit-keyframes myHueRotate {
  from {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
  }
}
@keyframes myHueRotate {
  from {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
  }
}
/* fix the first slide ever des react-swipeableView */
.react-swipeable-view-container {
  transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important;
}
