/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quantico'), local('Quantico-Regular'), url(https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Quantico';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Quantico-Bold'), url(https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.textDrawer {
  font-family: 'Quantico';
}
/* Colors */
.wrapper {
  height: 30vh;
}
.drawerInstall {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.iconDrawer {
  width: 15vh;
  height: 15vh;
  padding: 3vw;
}
.textDrawer {
  font-weight: bold;
  font-size: 3vh;
  padding: 2vw;
  text-align: center;
}
.buttons {
  padding-top: 2vh;
  display: flex;
  justify-content: space-evenly;
}
.btnDrawer {
  padding: 4vw;
}
